.auth-section {
  width: 100%;
  min-height: calc(100vh - 80px);
  padding: 40px 0;
  color: black;
  .header {
    margin-bottom: 0px;
  }
  .verify-email-container {
    max-width: 1098px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.9294117647);
    border-radius: 18px;
    padding: 30px 80px;
    text-align: center;
  }
  .section-heading {
    margin: 40px 0;

    .back-btn {
      width: 40px;
      height: 40px;
      display: block;
    }
  }
  .form-item {
    text-align: center;
    .input-box {
      width: 530px;
    }
  }
  .login-box {
    margin-bottom: 60px;
  }
  .top-footer {
    margin-top: 20px;
  }
  .footer .bottom-footer {
    padding: 150px 0 0;
  }
}

.header {
  margin-bottom: 20px;
  .link-wrapper {
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  &.fixed-header {
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 1);
    // background-color: rgba($color: #000000, $alpha: 0.8);
    max-width: 570px;
    width: 100%;
    padding: 10px 0;
    top: 0;
    margin-bottom: 0;
    z-index: 999;
  }

  .logo {
    max-width: 120px;
    margin: 0 auto;
    height: 134px;
    @media only screen and (max-width: 767px) {
      max-width: 80px;
      margin-bottom: 15px;
    }

    img {
      width: 100%;
    }
  }
  .subcription-details {
    margin-right: 25px;
    @media only screen and (max-width: 767px) {
      margin: 0;
      width: 70%;
    }
    img {
      position: relative;
      top: -6px;
    }
  }
  .user-name {
    display: flex;
    justify-content: end;
  }
  .user-name,
  .subcription-details {
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #000000;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }

    b {
      font-weight: 400;
      color: #e6352f;
      padding-left: 3px;
    }
    img {
      margin-right: 10px;
      @media only screen and (max-width: 767px) {
        margin-right: 5px;
        width: 16px;
      }
    }
  }

  .profile-dropdown {
    position: relative;
    @media only screen and (max-width: 767px) {
      width: 30%;
    }
    ul {
      list-style-type: none;
      background-color: white;
      border-radius: 4px;
      color: black;
      padding: 10px;
      position: absolute;
      width: 180px;
      bottom: -100px;
      right: 0;
      z-index: 999;
      margin-bottom: 0;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;
        padding: 6px 0;

        &:last-child {
          margin-bottom: 0;
          border: none;
          padding-bottom: 0;
        }
        img {
          margin-right: 5px;
        }
      }
    }
  }
}

.payment-status-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba($color: #000000, $alpha: 0.5);
  width: 100%;
  height: 100%;

  .status-modal-content {
    background-color: white;
    padding: 15px;
    height: auto;
    width: 100%;
    max-width: 320px;
    border-radius: 4px;

    h6 {
      color: black;
    }

    img {
      width: 50px;
    }
  }
}

ul {
  list-style-type: none;
}

.footer {
  .bottom-footer {
    font-family: "Poppins", sans-serif;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      font-size: 13px;
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
    p {
      font-family: "Poppins", sans-serif;
      color: black;
    }
    a {
      font-family: "Poppins", sans-serif;
      color: black;
      &:hover {
        color: $primary;
      }
    }
  }
  .top-footer {
    ul {
      padding: 0;
      display: flex;
      justify-content: center;
      margin: 0;
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }
      li {
        font-size: 20px;
        font-weight: 500;
        &:first-child {
          margin-right: 15px;
        }
        a {
          color: black;
          display: block;
          i {
            margin-right: 10px;
          }
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}

.home-section {
  padding: 20px 0;
  margin-top: 120px;
  .gray-wrapper {
    max-width: 1098px;
    margin: 0 auto;
    background-color: #ffffffed;
    border-radius: 18px;
    padding: 30px 80px 30px;
    footer.footer {
      max-width: 100%;
    }
    .footer .bottom-footer {
      padding: 100px 0 0;
    }
    @media only screen and (max-width: 1700px) {
      padding: 30px 50px 30px;
    }
    @media only screen and (max-width: 1024px) {
      padding: 30px 50px 30px;
    }
    @media only screen and (max-width: 768px) {
      padding: 30px 30px 30px;
    }
    @media only screen and (max-width: 767px) {
      padding: 30px 15px 15px;
    }

    h3 {
      font-size: 40px;
      color: #000000;
      font-weight: 600;
      margin-top: 40px;
      margin-bottom: 20px;
      @media only screen and (max-width: 1024px) {
        font-size: 34px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 24px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
  .history-card-wrapper {
    min-height: calc(100vh - 400px);
    border: 2px solid #f5f5f5;
    border-radius: 10px;
    @media only screen and (max-width: 767px) {
      overflow-x: auto;
    }
    .history-header {
      display: flex;
      justify-content: space-between;
      background-color: #f5f5f5;
      border-radius: 10px 10px 0 0;
      padding: 20px 30px;
      @media only screen and (max-width: 768px) {
        padding: 15px 15px;
      }
      .item {
        font-size: 20px;
        color: #000000;
        font-weight: 500;
        line-height: 26px;
        width: 25%;
        @media only screen and (max-width: 768px) {
          font-size: 18px;
          line-height: 20px;
        }

        @media only screen and (max-width: 767px) {
          flex: 0 0 auto;
          width: 60%;
        }
      }
    }
  }
  .history-card-wrapper.actual {
    .history-header {
      .item {
        font-size: 20px;
        color: #000000;
        font-weight: 500;
        line-height: 26px;
        width: 33.33%;
        @media only screen and (max-width: 768px) {
          font-size: 18px;
          line-height: 20px;
        }
        @media only screen and (max-width: 767px) {
          flex: 0 0 auto;
          width: 60%;
        }
      }
    }
  }

  .change-password-wrapper {
    min-height: calc(100vh - 282px);
  }

  .history-card {
    padding: 20px 30px;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    // cursor: pointer;
    @media only screen and (max-width: 768px) {
      padding: 15px 15px;
    }
    &:hover {
      border-color: #e6352f;
      .info-item {
        color: #e6352f;
      }
    }
    .info-item {
      width: 25%;
      color: #000000;
      font-size: 15px;
    }
  }
  .history-card {
    .info-item {
      width: 33.33%;
      color: #000000;
      font-size: 15px;
      @media only screen and (max-width: 767px) {
        flex: 0 0 auto;
        width: 60%;
      }
    }
  }
  .empty-data-text {
    width: 100%;
    height: calc(100vh - 400px);
  }

  .tab-wrapper {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    border-bottom: 3px solid #707070;
    @media only screen and (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    .payment-btn {
      background: unset;
      outline: none;
      border: none;

      span {
        background: #e6352f;
        color: #fff;
        border-radius: 5px;
        font-size: 25px;
        font-weight: 500;
        padding: 8px 20px;
        display: inline-block;
        margin-bottom: 10px;
        height: unset;
        @media only screen and (max-width: 1024px) {
          font-size: 22px;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 18px;
        }
        &:hover {
          background-color: #000;
        }
      }
    }
    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      margin: 0;
      justify-content: space-around;
      position: relative;

      li {
        padding: 0;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-right: 36px;
        font-size: 25px;
        color: #000000;
        position: relative;
        margin-bottom: 5px;
        @media only screen and (max-width: 1024px) {
          font-size: 22px;
          margin-right: 20px;
        }
        &:before {
          position: absolute;
          content: "";
          background-color: transparent;
          height: 3px;
          width: 100%;
          bottom: -8px;
          left: 0;
        }

        &:hover,
        &.active {
          color: #e6352f;
          border-color: $primary;
          &:before {
            background-color: #e6352f;
          }
        }
      }
    }
  }
  footer.footer {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 50px;
  }
}

.form-item {
  position: relative;
  margin-bottom: 30px;
  text-align: left;

  label {
    position: absolute;
    margin-bottom: 0;
    top: 14px;
    left: 15px;
    background-color: $black;
    transition: top 0.2s ease;
    padding: 0 7px;

    &.active {
      top: -10px;
    }
  }

  .eye-icon {
    position: absolute;
    top: 12px;
    right: 10px;
    height: 20px;
    line-height: 20px;
  }
}

.login-type {
  color: $primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  span {
    margin-left: 0.5rem;
    color: #757575;
  }
  .login-type-tgl-btn {
    display: flex;
    align-items: center;

    button {
      border: 1px solid $primary;
      background-color: $white;
      color: $primary;
      cursor: pointer;
      width: 73px;
      height: 29px;
      transition: all 0.2s ease;

      &:first-child {
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .active {
      background-color: $primary;
      color: $white;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-box {
  width: 100%;
  height: 45px;
  background-color: transparent;
  outline: none;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
  color: #5a5a5a;
  padding: 0 15px;
  font-size: 15px;
  &[type="password"] {
    padding-right: 30px;
  }
}

.loader {
  padding: 40px;
  min-height: calc(100vh - 400px);
}

.spinner {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;

  & .path {
    stroke: $primary;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

.subscribedPlans {
  & > button {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100px;
    border-radius: 10px;
    border: none;
    background-color: $primary;
    color: $white;
    font-weight: 500;
    font-size: 38px;
    cursor: pointer;
  }
}
.subscribedPlansCard {
  background: #daedeb;
  border-radius: 10px;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  color: $black;
  position: relative;

  h5 {
    font-weight: 500;
    font-size: 38px;
  }

  p {
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 0.3rem;
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  button {
    display: grid;
    place-items: center;
    border: none;
    background-color: $primary;
    color: $white;
    font-weight: 500;
    font-size: 24px;
    width: 124px;
    border-radius: 10px;
    cursor: pointer;
  }
}

.subscribedPlansCard__expired {
  background: #dfe7f0;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.footer-elements {
  li {
    &:first-child {
      padding-right: 20px;
      position: relative;
      &:before {
        position: absolute;
        content: "";
        background: black;
        height: 19px;
        width: 2px;
        right: 9px;
        top: 2px;
        @media only screen and (max-width: 1024px) {
          height: 15px;
        }
      }
    }
  }
}
