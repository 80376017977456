.locationDropdown {
  .blackBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #484848;
    border-radius: 10px;
    height: 72px;
    padding: 0 2rem;
    margin: 1rem 0;
    font-weight: 400;
    font-size: 26px;
    color: $black;
  }

  .disabledSelectMain {
    border-color: #bababa;
    color: #bababa;
  }

  .dropdownPopup {
    background-color: $white;
    margin-bottom: 2rem;
    padding: 1rem 4rem 2rem 4rem;
    display: none;

    .blackBox {
      margin: 0.5rem 0;
    }
    .dropdownPopup__closeIcon {
      height: 87.71px;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: 400;
      font-size: 40px;
      color: $black;
      i {
        cursor: pointer;
      }
    }

    .dropdownPopup__search {
      height: 87.71px;
      input {
        flex-grow: 1;
        height: 100%;
        border: none;
        font-size: 33.3284px;
        &::placeholder {
          color: $black;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .dropdownPopup__locationList {
      height: 500px;
      overflow-y: scroll;

      .dropdownPopup__location {
        height: 87.71px;
        cursor: pointer;
        span {
          font-size: 33.3284px;
        }
      }
    }
  }

  .dropdownPopup__show {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .locationDropdown {
    .dropdownPopup {
      padding: 1rem 2rem;

      .dropdownPopup__search {
        input {
          flex-grow: unset;
          font-size: 28.3284px;
          max-width: 80%;
        }
      }

      .dropdownPopup__locationList {
        .dropdownPopup__location {
          span {
            font-size: 28.3284px;
          }
        }
      }
    }

    .dropdownPopup__show {
      display: block;
    }
  }
}
