$primary: #E6352F;
$black: #000000;
$white: #ffffff;

$font: 'Graphik', sans-serif;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;

$container-width: 100%;
$container-mx-width: 1600px;
$container-side-padding: 15px;