@media only screen and (max-width: 600px) {
    .login-section {
        .container, .signup-form {
            min-height: calc(100vh - 137px);
        }
    }
}

@media only screen and (max-width: 375px) {
    .history-card {
        h5 {
            font-size: 16px;
        }
    }
}

@media only screen and (max-width: 350px) {
    .header {
        .logo {
            width: 70px;
        }
    }

    .StripeCheckout {
        span {
            padding: 0 8px !important;
            font-size: 12px !important;
        }
    }
}