* {
  font-family: $font;
}

body {
  margin: 0;
  padding: 0;
  background-color: $black;
  font-weight: $font-regular;
  color: $white;
}

.container {
  width: $container-width !important;
  max-width: $container-mx-width !important;
}

a {
  color: $primary;
  transition: 0.3s all ease;

  &:hover {
    color: $black;
    text-decoration: none;
  }

  &.text-primary:hover {
    color: $primary !important;
  }
}

.text-primary {
  color: $primary !important;
}

.font-bold {
  font-weight: $font-bold;
}

.error {
  color: $primary;
  padding: 0 15px;
}

.button-full-width {
  width: 100%;
}

.StripeCheckout {
  color: $white;
  width: 100%;
  background: #e6352f !important;
  background-image: url("../images/stripe-powerby.png") !important;

  background-size: 300px 65px !important;
  background-repeat: no-repeat !important;
  background-position: 75% 50% !important;
  box-shadow: none;
  border: 1px solid #e6352f;
  height: 103px;
  font-size: 24px;
  margin-top: 20px;
  border-radius: 10px;
  @media only screen and (max-width: 768px) {
    background-size: 240px 50px !important;
    background-position: 86% 50% !important;
  }
  @media only screen and (max-width: 767px) {
    background-size: 198px 40px !important;
    background-position: 50% 80% !important;
  }
  &:focus,
  &:active {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  span {
    background-image: none !important;
    font-size: 30px !important;
    margin-right: 30%;
    box-shadow: none !important;
    font-family: "Graphik" !important;
    font-weight: 500 !important;
    @media only screen and (max-width: 768px) {
      font-size: 26px !important;
      margin-right: 26%;
    }
    @media only screen and (max-width: 767px) {
      font-size: 20px !important;
      margin-right: 0;
      height: 77px !important;
    }
  }
}

.button {
  height: 50px;
  border: none;
  padding: 0;
  line-height: 40px;
  color: $white;
  background-color: transparent;
  font-size: 15px;
  border-radius: 5px;

  &:focus,
  &:active {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button-bordered {
  border: 2px solid;
  line-height: 36px;
  padding: 0 30px;
  border-radius: 35px;
}

.button-bordered-primary {
  border-color: $primary;
  color: black;
}

.button--primary {
  background-color: $primary;
}

.modal-close-btn {
  padding: 0;
  position: absolute;
  right: 10px;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: 0;
  font-size: 20px;

  &:hover,
  &:focus,
  &:active {
    border: none;
    background-color: transparent;
  }
}

.modal-footer-area {
  border: none !important;
}

.modal-body {
  padding-top: 35px !important;
}

.modal-dialog {
  width: 90% !important;
  max-width: 90% !important;
}

// .payment-btn {
//     overflow: hidden;
//     display: inline-block;
//     background: red;
//     border: 0px;
//     padding: 1px;
//     text-decoration: none;
//     border-radius: 5px;
//     box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px;
//     cursor: pointer;
//     visibility: visible;
//     user-select: none;
//     span {
//         font-size: 14px;
//         position: relative;
//         padding: 0px 12px;
//         display: block;
//         height: 30px;
//         line-height: 30px;
//         color: rgb(255, 255, 255);
//         font-weight: bold;
//         box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
//         text-shadow: rgba(0, 0, 0, 0.25) 0px -1px 0px;
//         border-radius: 4px;
//     }
// }

.payment-ul-1 {
  bottom: -59px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.applyCoupon {
  display: flex;
  padding: 32px 20px;
  border-radius: 10px;
  background: #ffff;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  label {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    margin-right: 10px;
    @media only screen and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  .applyCouponSubDiv {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 767px) {
      align-items: center;
      flex-direction: column;
    }
    .applyCouponTxt {
      background: #fff;
      box-shadow: none;
      border: 1px solid #707070;
      width: 330px;
      height: 45px;
      padding-left: 20px;
      border-radius: 5px;
      @media only screen and (max-width: 767px) {
        width: 264px;
      }
    }
    .applyCouponBtn {
      background: #e6352f;
      color: #fff;
      box-shadow: none;
      border: 1px solid #e6352f;
      padding: 10px 15px;
      width: 100px;
      font-size: 15px;
      height: unset;
      border-radius: 5px;
      font-weight: 500;
      margin-left: 15px;
      @media only screen and (max-width: 767px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}

.SubscriptionList {
  margin-top: 10px;
  .sub-list-main-div {
    padding: 30px 35px;
    margin-bottom: 20px;
    display: flex;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      padding: 20px 15px;
      margin-bottom: 15px;
    }
    &:nth-child(1) {
      background-color: #a2d0ff30;
    }
    &:nth-child(2) {
      background-color: #c89de530;
    }
    &:nth-child(3) {
      background-color: #87f0e630;
    }
    .plan-content {
      display: flex;
      align-items: flex-start;
      width: 69%;
      padding-right: 70px;
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 10px;
        flex-direction: column;
        align-items: center;
      }
      input {
        top: 5px;
        position: relative;
        width: 17px;
        height: 17px;
        @media only screen and (max-width: 767px) {
          top: 3px;
        }
      }
    }
    .sub-title {
      font-size: 20px;
      font-weight: 600;
    }
    .sub-details {
      margin: 0;
      color: #000000;
      font-size: 15px;
      line-height: 28px;
    }
    .sub-price {
      font-weight: 700;
      font-size: 44px;
      @media only screen and (max-width: 1024px) {
        font-size: 34px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 30px;
      }
      .price-sign {
        padding-right: 10px;
      }
      .price {
        color: #e6352f;
        sub {
          color: #000000;
          font-size: 46%;
          display: inline-block;
          font-weight: 500;
        }
      }
    }

    .sub-list {
      padding-left: 8px;
      @media only screen and (max-width: 767px) {
        text-align: center;
        margin-top: 15px;
      }
      .sub-title {
        color: #000000;
      }
      .sub-details {
        margin: 0;
        color: #4b4b4b;
      }
    }
    .sub-price {
      color: #000000;
    }
  }
}
.subscribe-btn {
  width: 100%;
  background: #e4032e;
  color: white;
  box-shadow: none;
  border: 1px solid #e4032e;
  height: 50px;
  font-size: 24px;
  margin-top: 20px;
}
.subscribe {
  min-height: calc(100vh - 282px);
}

.login-col {
  background: #f5f5f5;
  padding: 110px;
  display: flex;
  @media only screen and (max-width: 1700px) {
    padding: 30px 50px 0;
  }
  @media only screen and (max-width: 1024px) {
    padding: 30px 15px 30px;
  }
  @media only screen and (max-width: 991px) {
    padding: 30px 15px 30px;
    height: auto;
  }
  .login-wrapper {
    height: 773px;
    background: #fff;
    border-radius: 18px;
    -webkit-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.09);
    padding: 50px 127px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    @media only screen and (max-width: 1700px) {
      padding: 50px 30px;
    }
    @media only screen and (max-width: 1024px) {
      padding: 30px 20px 0;
    }
    @media only screen and (max-width: 991px) {
      padding: 30px 20px 20px;
    }
    .section-heading {
      margin-bottom: 40px;
      margin-top: 30px;
      h3 {
        font-size: 50px;
        color: #000;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        @media only screen and (max-width: 1700px) {
          font-size: 40px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 30px;
          flex-direction: column-reverse;
        }
        img {
          margin-left: 10px;
          @media only screen and (max-width: 767px) {
            margin-left: 0;
            margin-bottom: 10px;
          }
        }
      }
      p {
        font-size: 22px;
        color: #000;
        line-height: 35px;
      }
    }

    .no-account-text {
      font-size: 22px;
      color: #000;
      font-weight: 500;
      line-height: 35px;
      margin-top: 35px;
      @media only screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }
  .header .logo {
    margin: 0 auto;
  }
}

.cover-col {
  padding: 110px 110px 0 110px;
  display: flex;
  flex-direction: column;

  justify-content: center;
  @media only screen and (max-width: 1700px) {
    padding: 110px 50px 0px;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1024px) {
    padding: 100px 15px 0px;
  }
  @media only screen and (max-width: 991px) {
    padding: 50px 15px 0px;
  }
  .we-cover {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: center;
    }
    .left {
      text-align: right;
      margin-right: 20px;
      @media only screen and (max-width: 767px) {
        margin-top: 0px;
        margin-right: 0;
        text-align: center;
      }
      h4 {
        font-size: 45px;
        font-weight: 600;
        @media only screen and (max-width: 1700px) {
          font-size: 32px;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 30px;
        }
        span {
          font-weight: 300;
          display: block;
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
        b {
          display: block;
          span {
            color: #e6352f;
            font-weight: 600;
            display: inline-block;
          }
        }
      }
    }
    .right {
      .stats {
        display: flex;
        font-size: 140px;
        color: #e6352f;
        line-height: 1;
        font-weight: 600;
        position: relative;
        @media only screen and (max-width: 1700px) {
          font-size: 115px;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 90px;
        }
        sup {
          top: 45px;
          font-size: 65%;
        }
      }
      @media only screen and (max-width: 767px) {
        position: relative;
        margin-top: 50px;
        &:before {
          content: "We Cover";
          width: 100%;
          font-size: 30px;
          font-weight: 300;
          transform: translate(-40%, 50%);
          position: absolute;
          left: 50%;
          top: -75px;
        }
      }
    }
  }
  .dont-have-account {
    background: #202020;
    border-radius: 10px;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    padding: 20px 10px;
    margin: 90px 0 50px;
    @media only screen and (max-width: 1700px) {
      margin: 35px 0 30px;
      font-size: 30px;
      padding: 15px 10px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 991px) {
      margin: 35px 80px;
    }
    @media only screen and (max-width: 767px) {
      max-width: 500px;
      margin: 20px auto;
      width: 100%;
    }
    @media only screen and (max-width: 450px) {
      font-size: 18px;
    }
  }
  .claim-text {
    text-align: center;
    margin-bottom: 140px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 190px;
    }
    @media only screen and (max-width: 991px) {
      margin-bottom: 80px;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
    p {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      @media only screen and (max-width: 1024px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 767px) {
        margin-bottom: 15px;
        line-height: 1.2;
      }
      b {
        font-size: 50px;
        font-weight: 700;
        color: #e6352f;
        @media only screen and (max-width: 1700px) {
          font-size: 40px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 30px;
        }
      }
    }
    h4 {
      font-size: 33px;
      font-weight: 500;
      @media only screen and (max-width: 1700px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 24px;
      }
      @media only screen and (max-width: 450px) {
        font-size: 20px;
      }
      span {
        color: #e6352f;
      }
    }
  }
}
.form-item .eye-icon svg {
  filter: brightness(0);
}
.login-page .top-footer {
  display: none;
}
.hidden {
  display: none;
}

.home-section.forgot-password .header .logo {
  margin: 0 auto;
}

.back-btn {
  font-size: 20px;
  display: flex;
  font-weight: 600;
  align-items: center;
}

.button-half-width {
  width: 200px;
}
